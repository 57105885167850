<template>
    <div class="patient-list" :class="{'App-main':$store.getters.isApp}">
        <div class="list-container" v-if="patientList.length!=0">
            <van-swipe-cell v-for="(item,index) in patientList" :key="index">
                <div class="list-item" @click="goDetails(item)">
                    <img src="@/assets/img/patientList-picwoman.png" v-if="item.gender==2"  class="picimg">
                    <img src="@/assets/img/patientList-picman.png" v-else  class="picimg">
                    <div class="item-info">
                        <p class="info-name">{{item.name}} <span class="info-tag">{{relationObj[item.relationship]}}</span> </p>
                        <p class="info-gender">{{item.gender==1?$t("patient.man"):$t("patient.woman")}}  {{item.age+$t("patient.annum")}}</p>
                    </div>
                </div>
                <template #right>
                    <div class="delete" @click="deletePatient(item.id,index)">{{$t("patient.delete")}}</div>
                </template>
            </van-swipe-cell>
        </div>
        <div class="container-add" v-if="patientList.length != 0">
            <p class="btn" @click="goAdd">{{$t("patient.emptybtn")}}</p>
        </div>
        <div class="patient-empty" v-if="patientList.length == 0">
            <img src="@/assets/img/patient-empty-img.png" alt="">
            <p class="txt">{{$t("patient.patientempty")}}</p>
            <div class="empty-btn" @click="goAdd">
                {{$t("patient.emptybtn")}}
            </div>
        </div>
        <history-patient :historyPatientShow="historyPatientShow" @confirmExist="confirmExist" />
    </div>
</template>

<script>
import { patientQuery, patientDelete, patientHsitoryQuery, addPatient } from "@/api/patient";
import { relationObj } from "@/utils/staticData";
import historyPatient from "./historyPatient.vue";
export default {
    components:{historyPatient},
    data(){
        return{
            patientList:[],
            relationObj,
            historyPatientShow:false,
            exisitPatient:[]
        }
    },
    created(){
        document.title = this.$t("patient.patientList")
        this.getPatientQuery();
        this.getExisitData();
    },

    methods:{
        goAdd(){
            if(this.exisitPatient.length!=0){
                this.historyPatientShow = true;
            }else{
                this.$router.push({
                    path:"/patient/add"
                })
            }
        },
        getExisitData(){
            patientHsitoryQuery().then(res=>{
                this.exisitPatient = res.data.data;
            })
        },
        confirmExist(row){
            addPatient({
                id:row
            }).then(res=>{
                if(res.data.errorCode == 0){
                    this.$Toast(this.$t("comonTxt.operationsuccess"))
                    this.getPatientQuery()
                    this.getExisitData();
                }else{
                    this.$Toast(this.$t("comonTxt.operationfail"))
                }
            })
        },
        goDetails(detail){
            this.$router.push({
                path:"/patient/patientDetails",
                query:{
                    patientId:detail.id
                }
            })
        },
        getPatientQuery(){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            patientQuery({}).then(res=>{
                toast.close()
                if(res.data.errorCode == 0){
                    this.patientList = res.data.data;
                }else{
                    this.$Toast(this.$t("patient.getPatientfail"))
                }
            })
        },
        deletePatient(id,index){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            patientDelete({id}).then(res=>{
                toast.close()
                 if(res.data.errorCode == 0){
                    this.$Toast(this.$t("comonTxt.operationsuccess"))
                    this.patientList.splice(index,1);
                }else{
                    this.$Toast(this.$t("comonTxt.operationfail"))
                }
            })
        }   
    }
}
</script>

<style lang="less" >
    .patient-list{
        height: 100vh;
        background: #F5F6FA;
        // height: auto;
        overflow: auto;
        display: flex;
        flex-direction: column;
        .list-container{
            flex: 1;
            overflow: auto;
            padding: 0 0.6rem;
            .van-swipe-cell__right{
                display: flex;
                align-items: center;
                justify-content: center;
                background: linear-gradient(0deg, #499FFF 0%, #49BFFF 100%);
                border-radius: 0 0.32rem 0.32rem 0;
                .delete{
                    width: 2rem;
                    text-align: center;
                    font-size: 0.56rem;
                    color: #FFFFFF;
                }

            }
            .list-item{
                background: #FFFFFF;
                border-radius: 0.32rem;
                margin-top: 0.6rem;
                padding: .4rem 0.6rem;
                display: flex;
                align-items: center;
                
                .picimg{
                    width: 2.2rem;
                    height: 2.2rem;
                }
                .item-info{
                    margin-left: 0.6rem;
                    .info-name{
                        font-size: 0.64rem;
                        font-family: PingFang SC;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        color: #333333;
                        .info-tag{
                            font-size: 0.44rem;
                            padding: 0.06rem 0.18rem;
                            font-family: PingFang SC;
                            font-weight: 300;
                            color: #49A7FF;
                            background: #E1EEFD;
                            margin-left: 0.3rem;
                            border-radius: 0.1rem;
                        }
                    }
                    .info-gender{
                        margin-top: 0.46rem;
                        font-size: 0.56rem;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #666666;
                    }
                }
            }
        }
        .container-add{
            flex: 0 0 auto;
            padding: 0.8rem 0;
            .btn{
                width: 13.6rem;
                height: 2rem;
                background: linear-gradient(0deg, #499FFF 0%, #49BFFF 100%);
                border-radius: 1rem;
                margin: 0 auto;
                font-size: 0.72rem;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
        .patient-empty{
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            img{
                width: auto;
                height: 6rem;
            }
            .txt{
                font-size: 0.56rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9B9B9B;
                margin: 0.6rem auto 0.8rem auto;
            }
            .empty-btn{
                width: 12.8rem;
                margin: 0 auto;
                height: 1.6rem;
                background: linear-gradient(0deg, #499FFF 0%, #49BFFF 100%);
                border-radius: 0.8rem;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 0.72rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
            }
        }
    }
    .App-main.patient-list{
        height: 100%;
    }
</style>