<template>
	<div class="patientlayout">
		<van-overlay :show="historyPatientShow" class-name="select-patient" :lock-scroll="false">
			<div class="empty" @click="close"></div>
			<div class="select-box">
				<p class="head-title">
					{{$t("patient.existSelectPatient")}}
					<van-icon name="cross close-icon" @click="close" />
				</p>
				<van-list class="select-list">
					<div class="select-item" @click="setPatientindex(index)" v-for="(item , index) in  existHistoryPatientList" :key="index" :class="{'selectAvtive':index == selectindex}">
						<img class="item-img" src="@/assets/img/patientList-picwoman.png" v-if="item.gender==2"  >
                    	<img class="item-img" src="@/assets/img/patientList-picman.png" v-else  >
						<div class="item-info">
							<p class="info">
								<span class="name">{{item.name}}</span>
								<span class="tag">{{relationObj[item.relationship]}}</span>
							</p>
							<p class="info-gender">
								{{item.gender==1?$t("patient.man"):$t("patient.woman")}} &nbsp; {{item.age + $t("patient.annum")}}
							</p>
						</div>
						<p v-if="index == selectindex" class="item-active"></p>
					</div>
				</van-list>
				<div class="addpatientbtn">
					<span @click="goPath">{{$t("patient.addpatient")}}</span>
					<span :class="{'confirm':selectindex||selectindex==0}" @click="confirmpatient">{{$t("patient.bindbtnconfirm")}}</span>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
import { patientHsitoryQuery } from "@/api/patient";
import { relationObj } from "@/utils/staticData";

export default {
    props:{
        historyPatientShow:{
            type:Boolean,
            default:false,
        }
    },
    watch:{
        historyPatientShow(){
            this.selectindex = null;
            this.getPatientData()
        }
    },
	data(){
		return{
			selectPatient:false,
			selectindex:null,
            existHistoryPatientList:[],
			relationObj
		}
	},
	created () {
      this.getPatientData();  
    },
    methods:{
		close(){
			this.$parent.historyPatientShow = false;
		},
		setPatientindex(index){
			this.selectindex = index;
		},
		goPath(){
			this.$router.push({
				path:"/patient/add"
			})
			this.$store.commit('user/SET_PATIENTSHOW',false)
			this.selectPatient = false;
		},
		confirmpatient(){
            if(this.selectindex||this.selectindex == 0){
                this.$emit("confirmExist",this.existHistoryPatientList[this.selectindex].id)
                this.close();
            }
		},
		// 获取就诊人数据
		getPatientData(){
			
			patientHsitoryQuery({}).then(res=>{
				if(res.data.errorCode == 0){
                    this.existHistoryPatientList = res.data.data;
				}else{
					this.$Toast(this.$t("patient.patientfail"))
				}
			})
		}
	}
}
</script>

<style lang="less" scoped>

.select-patient{
	display: flex;
	flex-direction: column;
	.empty{
		flex: 1;
	}
	.select-box{
		flex: 0 0 auto;
		border-radius: 0.64rem 0.64rem 0 0;
		background: #F5F6FA;
		.head-title{
			height: 3.4rem;
			line-height: 3.4rem;
			text-align: left;
			font-size: 0.72rem;
            padding-left: 0.6rem;
            box-sizing: border-box;
			position: relative;
            background-image: url("~@/assets/img/existPatient-back.png");
            background-repeat: no-repeat;
            // background-position: left;
            background-size: 100% 3.4rem;
			font-family: PingFang SC;
			font-weight: 600;
			color: #333333;
			width: 100%;
            position: relative;
			.close-icon{
				position: absolute;
				width: 0.8rem;
				height: 0.8rem;
				font-size: 0.8rem;
				padding: 0.5rem;
				right: 0.6rem;
				top: 50%;
				transform: translate(0,-50%);
			}
		}
		.select-list{
			height: 10rem;
			padding: 0 0.6rem;
			overflow-y: auto;
			// -webkit-overflow-scrolling: touch;
			.select-item{
				padding: 0.4rem 0.6rem;
				display: flex;
				align-items: center;
				border-radius: 0.32rem;
				background: #FFFFFF;
				margin-bottom: 0.3rem;
				.item-img{
					height: 2.16rem;
					width: 2.16rem;
					flex: 0 0 auto;
					margin-right: 0.6rem;
				}
				.item-info{
					flex: 1;
					.info{
						margin: initial;
						display: flex;
						align-items: center;
						margin-bottom: 0.4rem;
						.name{
							margin-right: 0.3rem;
							font-size: 0.64rem;
							font-family: PingFang SC;
							font-weight: 500;
							color: #333333;
						}
						.tag{
							font-size: 0.44rem;
							font-family: PingFang SC;
							font-weight: 300;
							color: #49A7FF;
							border-radius: 0.06rem;
							padding: 0.06rem 0.2rem;
							background: #E1EEFD;
						}
					}
					.info-gender{
						font-size: 0.56rem;
						font-family: PingFang SC;
						font-weight: 400;
						color: #666666;
						margin: initial;
					}
				}
				.item-active{
					width: 0.96rem;
					height: 0.96rem;
					margin: 0 0.4rem;
					background: url("~@/assets/img/patienticonAvtive.png") no-repeat;
					background-size: 100% 100%;
				}
			}
			.selectAvtive{
				padding: 0.36rem 0.6rem;
				border: 0.04rem solid #49A7FF;
			}
		}
		.addpatientbtn{
			
			display:flex;
			padding: 0 0.6rem;
			align-items: center;

			span{
				font-size: 0.6rem;
				margin: 0.6rem 0;
				padding: 0.6rem;
				flex: 1;
				
				text-align: center;
				border-radius: 1.4rem;
				&:first-child{
					margin-right: 0.6rem;
					background: none;
					color: #49A7FF;
					border: 0.02rem solid #49A7FF;
				}
				&:last-child{
					background: #ECEEF3;
					color: #838385;
				}
			}
			span.confirm{
				background: #49A7FF;
				color: #FFFFFF;
			}
		}
	}
}
.layoutPatient{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	.close-btn{
		width: 1.2rem;
		height: 1.2rem;
		margin-top: 2.4rem;
		background: url("~@/assets/img/tj_close_icon.png") no-repeat;
		background-size: 100% 100%;
	}
	.addPatient{
		width: 11.4rem;
		background: #FFFFFF;
		height: auto;
		// overflow: hidden;
		border-radius: 0.4rem;
		.banners{
			border-radius: 0.4rem 0.4rem 0 0;
			height: 4.8rem;
			background: #DFF2FF;
			position: relative;
			&::after{
				position: absolute;
				width: 100%;
				height: 6rem;
				content: ' ';
				left: 0;
				bottom: 0;
				background: url("~@/assets/img/patient-add-banner.png") no-repeat;
				background-size: 100% 100%;

			}

		}
		.title{
			font-size: 0.84rem;
			font-family: PingFang SC;
			font-weight: 500;
			color: #303133;
			line-height: 0.8rem;
			margin: 1rem auto 0.7rem auto;
			text-align: center;
		}
		.desctxt{
			font-size: 0.64rem;
			font-family: PingFang SC;
			font-weight: 400;
			color: #666666;
			padding: 0 0.6rem;
			line-height: 0.92rem;
			text-align: center;
			box-sizing: border-box;
		}
		.add-btn{
			margin:0.7rem auto 1.2rem auto;
			width: 8.2rem;
			height: 1.8rem;
			line-height: 1.8rem;
			font-size: 0.72rem;
			
			font-family: PingFang SC;
			font-weight: 500;
			color: #FFFFFF;
			text-align: center;
			border-radius: 0.9rem;
			background: linear-gradient(0deg, #499FFF 0%, #49BFFF 100%);
		}
	}
}
</style>